
.SearchStyle {
    width: 40%;
}
.search-bar {
    border: none;
    padding:5px 10px;
    background-color: #00153E;
    border-bottom: 0.5px solid #1A76D2;
    color: white;
    width: 80%;
    font-size: medium;
}

.search-bar::placeholder {
    color: white;
}

.search-bar:focus {
    outline: none;
    border-bottom: 1px solid #1A76D2;
}

.search-button {
    color: #1A76D2;
}

.taxonomy-results-wrapper {
    position: absolute;
    top: 100%;
    left: 33%;
    background-color: grey;
   /* border: 1px solid #ccc; */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    min-width: 300px;
}

.column {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    padding: 0 10px;
    box-sizing: border-box;
    min-width: 120px;
}

.column h3 {
    margin-top: 0;
}

.taxonomy-results li:hover {
    background-color: #f5f5f5;
    color: black;
}

.taxonomy-results {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.taxonomy-results li {
    cursor: pointer;
    padding: 5px;
    margin: 5px;
    background-color: grey;
}

.taxonomy-results li:hover {
    background-color: #e0e0e0;
}


.close-results-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    cursor: pointer;
}

