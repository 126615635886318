.audit-table {
    width: 98%;
    border-collapse: collapse;
    margin: 20px;
}

.audit-table th,
.audit-table td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.audit-table th {
    background-color: #f2f2f2;
}

.audit-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.audit-table tr:nth-child(odd) {
    background-color: #e0e0e0;
}

.small-column {
    max-width: 120px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
