
.navbar {
    /* width: 100%; */
    background-color: #00153E;
    /*padding: 1rem!important;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    /*font-size: calc(10px + 2vmin);*/
    color: white;
    padding-top: 0px;
    padding-bottom: 0px;
}

.app-logo {
    width: 50px;
    height: auto;
}

.menu-group {
    display: flex;
    color: #61DBFB!important;
}

.notation {
    font-size: small
}

