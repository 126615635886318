
.card-error {
    font-style: oblique;
    font-family: "72 Monospace";
    color: red;
}

.card-header {
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card a.card-link {
    cursor: pointer;
}

.card-text {
    text-align: left;
    margin-bottom: 0px !important;
}

.card-text p {
    margin-bottom: 0px !important;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 999; /* Ensure the overlay appears above other elements */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.card-edit {
    font-weight: normal;
    display: block;
    border-radius: 6px;
    padding: 20px;
    border: 2px solid #ccc;
    max-width: 1200px;
    background-color: #f9f9f9;
}

.card-audit {
    font-weight: normal;
    display: block;
    border-radius: 6px;
    padding: 20px;
    border: 2px solid #ccc;
    width: 1200px;
    background-color: #f9f9f9;
}

.card-edit form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

.card-edit label {
    text-align: left;
    margin-bottom: 5px;
}

.card-edit input[type="text"] {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
}

.card-edit button {
    margin-top: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    width: 100%;
}

.card-edit button[type="submit"] {
    margin-right: 10px;
}
