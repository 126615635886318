.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.logo {
  position: relative;
  display: inline-block;
}

.App-logo {
  width: 80%;
  height: auto;
}

.spinning {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.buttonOnTabs {
  position: absolute;
  top: 73px;
  right: 16px; /* Adjust the spacing as needed */
  display: flex;
  align-items: center;
}

/* CSS for Message Component */
.message {
  position: fixed;
  top: 73px;
  left: 20px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

/* CSS for Warning Message */
.message.warning {
  background-color: #f5a623; /* Yellow */
}

/* CSS for Info Message */
.message.info {
  background-color: #4caf50; /* Green */
}


