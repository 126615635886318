*{
    margin: 0;
    font-size: small;
}

.container {
    /*margin-top: 2rem;*/
}

.row {
    /*margin-top: 2rem!important;*/
    width:99%;
}

.left{
    /* padding: 1rem; */
}

.right {
    /*border-left: 1px solid #00153E;*/
    /* height: 100%; */
    /* padding: 1rem; */
    /* background-color: #ececec; */
}
.box {
    border-radius: 6px;
    padding: 20px;
    border: 1px solid rgb(227, 227, 227);
    box-shadow: 6px 6px 15px rgb(246, 246, 246);
}

.box h2 {
    color: #1A76D2;
}


.container {
    margin-right: 0px;
    margin-left: 0px;
    overflow-x: hidden
}


