.add-edit-button-container {
    position: relative;
    display: inline-block;
}

.add-edit-button {
    background-color: #1976d2; /* Set this to your primary color */
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    margin-right: 10px;
    margin-left: 10px;
    height: 32px;
}

.menu-options {
    position: absolute;
    top: calc(100%); /* Position below the button */
    left: 45%; /* Center horizontally */
    transform: translateX(-50%); /* Center horizontally */
    color: white;
    background-color: #1976d2;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    width: 120px;
    z-index: 1;
    margin-right: 10px;
    margin-left: 10px;
}


.menu-option {
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.menu-option:hover {
    background-color: #f0f0f0;
    color:black;
}
